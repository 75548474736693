.cardContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardContainer div.infoContainer {
    display: flex;
    flex-direction: column;
}

.cardContainer div.infoContainer p {
    font-size: 14px;
    margin: 0;
}