.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader svg {
    margin-right: 10px;
}

.noAppointmentHolder {
    padding: 70px 0;
    text-align: center;
}