
.tracker {
    position: relative;
    width: 100%;
    height: 80px;
    background: white;
    z-index: 2;
    margin-top: 30px;
}

.tracker.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.tracker .trackerContainer {
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
    text-align: center;
    overflow-x: scroll;
}

.tracker .indexList {
    display: inline-flex;
    align-items: center;
    height: 100%;
    margin: auto;
}

.tracker .indexHolder {
    display: flex;
    align-items: center;
}

.tracker .indexHolder>div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    height: 25px;
    border: 2px solid var(--lighter-grey-color);
    border-radius: 50%;
    font-size: 13px;
    color: var(--dark-grey-color);
    cursor: pointer;
    transition: all 0.3s;
}

.tracker .indexHolder.active>div, .tracker .indexHolder>div:hover {
    color: var(--primary-black-color);
    border: 2px solid var(--primary-black-color) !important;
}

.tracker .indexHolder.done>div {
    border: 2px solid var(--dark-grey-color);
}

.tracker .indexHolder.hasError>div {
    border: 2px solid var(--error-red-color);
    color: var(--error-red-color);
}

.tracker .indexHolder>span {
    display: block;
    height: 2px;
    width: 15px;
    background: var(--lighter-grey-color);
    transition: all 0.3s;
}

.tracker .indexHolder.active>span, .tracker .indexHolder.done>span {
    background: var(--dark-grey-color);
}

.tracker .indexHolder:first-of-type>span {
    display: none;
}