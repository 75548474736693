/* FONTS */

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* VARIABLE */

:root {
  /* Colors */
  --primary-black-color: #1C1C1C;
  --dark-grey-color: #757575;
  --light-grey-color: #B4B4B4;
  --lighter-grey-color: #E9ECEC;
  --error-red-color: #ee075a;
  --success-green-color: #337357;
  --warning-yellow-color: #eab356;

  /* Shadow */
  --drop-shadow: 0 4px 16px 0 var(--light-grey-color);

  /* Datetime Picker */
  --box-height: 50px;
}

/* GENERAL */

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html, body {
  position: relative;
  background: var(--lighter-grey-color);
  padding: 0;
  margin: 0;
  font-family: 'Poppins';
  font-weight: 400;
  color: var(--primary-black-color);
  font-size: 16px;
  line-height: 160%;
}

.page {
  padding-top: 100px;
}

.container {
  width: 100%;
  max-width: 1060px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.preauth-container {
  width: 100%;
  max-width: 450px;
  margin: auto;
  padding: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;
  overflow-x: hidden;
  min-height: 40vh;
}

h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 110%;
}

h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
}

h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
}

hr {
  border-top: 1px solid var(--dark-grey-color);
  margin: 20px 0;
}

.subtitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
}

.pretitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

b {
  font-weight: 700;
}

label {
  display: block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  margin: 3px 0;
}

a {
  color: var(--primary-black-color);
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  text-decoration: underline;
}

p a {
  font-size: 16px;
}

a:hover {
  text-decoration: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.has-error, .urgent {
  color: var(--error-red-color);
}

.dual-button-holder {
  display: flex;
  justify-content: center;
}

.dual-button-holder button {
  width: auto;
  max-width: 100%;
  display: inline-flex;
  margin-right: 15px !important;
  min-width: 250px;
}

.dual-button-holder button:last-of-type{
  margin-right: 0px !important;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}
.justify-space-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.flex-end {
  justify-content: flex-end;
}

.flex-start {
  justify-content: flex-start;
}

.disclaimer {
  font-size: 12px;
}

/* Form */

.input-holder {
  margin-top: 20px;
  margin-bottom: 30px;
}

.row {
  width: 100%;
  display: flex;
}

.row .twenty {
  flex: 0.2;
}

.row .thirty {
  flex: 0.333;
}

.row .forty {
  flex: 0.4;
}

.row .fifty {
  flex: 0.5;
}

.row .sixty {
  flex: 0.6;
}

.row .eighty {
  flex: 0.8;
}

.row .hundred {
  flex: 1;
}

.row .input-holder {
  margin-right: 20px;
  margin-bottom: 0px;
}

.row .input-holder:last-of-type {
  margin-right: 0px;
}

.row button {
  margin: 0;
  height: 48px;
  min-height: auto;
  box-sizing: border-box;
}

@media (max-width: 800px) {

  .row {
    display: block;
  }

  .row .input-holder {
    margin-right: 0px;
  }

  .dual-button-holder button {
    min-width: 80px;
  }

}

@media (max-width: 700px) {

  .page {
    padding-top: 80px;
  }  

  h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 110%;
  }

  h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 120%;
  }

  h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
  }

}

.spinorama {
  -webkit-animation: spinorama 4s linear infinite;
  -moz-animation: spinorama 4s linear infinite;
  -ms-animation: spinorama 4s linear infinite;
  -o-animation: spinorama 4s linear infinite;
  animation: spinorama 4s linear infinite;
}

@keyframes spinorama {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Stripe react style ovewrite */

.stripe-element-wrapper {
  height: 48px;
  width: 100%;
  border: 1px solid var(--light-grey-color);
  padding: 14px 20px;
  box-sizing: border-box;
  transition: all 0.3s;
}

.stripe-element-wrapper:hover {
  border: 1px solid var(--primary-black-color);
}