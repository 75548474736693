.holder {
    padding-top: 50px;
}

.block {
    position: relative;
    height: 400px;
    box-sizing: border-box;
    transform: scale(0.90);
    opacity: 0.5;
    transition: all 0.2s;
    border: 1px solid transparent;
}

.block.focused {
    transform: scale(1);
    opacity: 1;
    z-index: 1;
}

.block button {
    margin-top: 15px;
}

.errorsHolder {
    margin-top: 20px;
    text-align: right;
}

.errorsHolder a {
    display: block;
    font-size: 14px;
    color: var(--error-red-color);
}
