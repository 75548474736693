.container .imgHolder {
    text-align: center;
}

.container img {
    max-width: 120px;
    margin-bottom: 0px;
}

.container a {
    display: block;
    margin-bottom: 10px;
}

.consent a {
    display: inline;
    font-size: 16px;
}
