.divider {
    border-bottom: 1px solid #FFF;
    background-color: #DADADA;
    height: 1px;
    margin: 0.5em 0 1.5em;
}

.divider span {
    display: block;
    width: 50px;
    height: 1px;
    background-color: var(--primary-black-color);
}

.clinicListContainer {
    display: flex;
    justify-content: space-between;
    align-items: top;
    cursor: pointer;
}

.clinicListContainer svg {
    margin-top: 15px;
}

.enter {
    max-height: 0;
}

.paddingTop {
    box-sizing: border-box;
}
.enterActive {
    max-height: 454px;
    padding-top: 1.5rem;
    transition: max-height 0.5s;
}

.enterDone {
    padding-top: 1.5rem;

}
.exit {
    max-height: 454px;
    padding-top: 1.5rem;
}
.exitActive {
    max-height: 0;
    padding-top: 0;
    transition: max-height 0.5s, padding-top 0.5s;
}
