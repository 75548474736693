.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    z-index: 500;
    box-sizing: border-box;
    padding: 0 30px;
    padding-top: 150px;
    text-align: center;
    pointer-events: none;
    opacity: 0;
    transition: all 0.3s;
}

.background.visible {
    opacity: 1;
    pointer-events: all;
}

.modal {
    margin: auto;
    display: inline-block;
    background: white;
    text-align: left;
    width: 600px;
    max-width: 100%;
    max-height: 70vh;
    transform: translateY(-50px);
    transition: all 0.3s;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
}

.background.visible .modal {
    transform: translateY(0px);
}

.modal .header {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    justify-content: space-between;
    border-bottom: 1px solid var(--light-grey-color);
}

.modal .header button {
    margin-left: 20px;
    border: none;
    background: none;
    cursor: pointer;
    color: var(--dark-grey-color);
    transition: all 0.3s;
}

.modal .header span:first-child {
    font-size: 16px;
    font-weight: bold;
}
.modal .header span:nth-child(2) {
    font-size: 14px;
    font-style: italic;
}

.modal .header button:hover {
    color: var(--primary-black-color);
}

.modal .content {
    padding: 20px;
    overflow-y: scroll;
    max-height: 100%;
    position: relative;
}

.modal .content p, .modal .content button {
    margin: 0;
    margin-bottom: 15px;
}