.footer {
    width: 100%;
    background-color: var(--lighter-grey-color);
    box-sizing: border-box;
    position: relative;
}

.footer .logo {
    display: block;
    width: 100px;
    margin: 30px 0;
}

.footer .inner {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 1rem;
    z-index: 1;
}

.footer .schedule {
    display: flex;
    margin-bottom: 5px;
}

.footer .schedule b {
    width: 50px;
}

.footer h4 {
    margin-bottom: 0;
}

.footer a {
    font-size: 16px;
    line-height: 160%;
    text-decoration: none;
    margin-right: 5px;
}

.footer a:hover {
    text-decoration: underline;
}

.footer .infoHolder {
    margin-bottom: 10px;
}

.footer .disclaimer {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;

    margin-top: 20px;
    text-align: center;
}

.footer .clinicInfo {
    margin-bottom: 20px;
}

.footer .clinicInfo p {
    margin: 0;
    margin-top: 5px;
    font-size: 14px;
}

.footer .clinicInfo a  {
    font-size: 12px;
}

/** MOBILE **/

@media (max-width: 700px) {

    .footer .inner {
        flex-direction: column;
        align-items: flex-start;
        width: auto;
    }

    .footer .inner>div {
        margin-bottom: 20px;
    }

}
