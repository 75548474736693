.pageHolder {
    overflow: hidden;
}

.page {
    animation-name: slide-in-left;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.consentModal {
    width: 850px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader svg {
    margin-right: 10px;
}

@keyframes slide-in-left {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
