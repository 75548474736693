.header {
    background: url("../../assets/images/background.png");
    background-size: cover;
    background-position: center center;
    height: 110vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 70px;
}

.myFile {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.myFile p {
    margin: 0;
    padding: 40px;
    border: 2px solid var(--primary-black-color);
    line-height: 110%;
    font-size: 52px;
}

.navigation {
    background: rgba(255, 255, 255, 0.65);
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: 0;
    padding: 30px 0;
    text-align: center;
}

.navigation p {
    font-size: 28px;
    margin-bottom: 40px;
}

.navigation div {
    margin: auto;
}

.navigation div button {
    background: none;
    border: none;
    margin: 0 30px;
    cursor: pointer;
    transition: all 0.3s;
}

.navigation div button h3 {
    font-weight: 300;
}

.navigation div a.disabledLink {
    position: relative;
}

.navigation div a.disabledLink button div::after {
    content: attr(disabled-tool-tip);
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px;
    border: 2px solid var(--primary-black-color);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .9);
    color: black;
    text-align: center;
    z-index: 2;
    font-size: 1rem;
    transform: scale(0);
    transition: background-color ease-out 150ms,
    transform ease-out 150ms,
    bottom ease-out 150ms;
}
.navigation div a.disabledLink button div:hover::after, .navigation div a.disabledLink button:hover div::after {
    transform: scale(1);
    bottom: 30%;
}

@supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
    .navigation div a.disabledLink button div::after, .navigation div a.disabledLink button:hover div::after  {
        background-color: unset;
        -webkit-backdrop-filter: blur(2em);
        backdrop-filter: blur(2em);
        transition:
                backdrop-filter ease-in-out 150ms,
                transform ease-out 150ms 150ms,
                bottom ease-out 150ms 150ms;
    }
    .navigation div a.disabledLink button div:hover::after {
        backdrop-filter: blur(2em);
        transform: scale(1);
        bottom: 30%;
    }
}


.navigation div button:hover {
    transform: scale(1.1);
}

.navigation div button:focus {
    outline: none;
}

.navigation div button img {
    height: 90px;
}


@media (max-width: 800px) {

    .header {
        height: 70vh;
        padding-top: 50px;
        padding-bottom: 0;
    }

    .myFile p {
        padding: 30px;
        font-size: 28px;
    }

    .navigation p {
        display: none;
    }

    .navigation div {
       display: flex;
       justify-content: center;
    }    

    .navigation div button {
        margin: 0 15px;
    }

    .navigation div button img {
        height: 50px;
    }

}

@media (max-width: 00px) {
}