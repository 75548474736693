.button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--primary-black-color);
    min-height: 56px;
    height: auto;
    padding: 15px 15px;
    width: 100%;
    margin-top: 10px;
    border: 2px solid var(--primary-black-color);
    box-sizing: border-box;
    color: white;
    cursor: pointer !important;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;

    transition: all 0.3s;
}

.button:hover, .button:focus {
    background-color: var(--dark-grey-color);
    border: 2px solid var(--dark-grey-color);
}

.button.secondary {
    background: none;
    color: var(--primary-black-color);
}

.button.secondary:hover, .button.secondary:focus {
    background-color: var(--light-grey-color);
    border: 2px solid var(--primary-black-color);
}

.button:focus {
    outline: none;
}

.button[disabled] {
    pointer-events: none;
    opacity: 0.3;
}

.button svg {
    margin-right: 10px;
}