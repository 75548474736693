.applyButton {
    margin-top: 30px !important;
}

.couponHolder {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
}

.couponHolder span {
    margin-right: 10px;
    color: var(--dark-grey-color);
}

.couponHolder b {
    margin-right: 10px;
}

.couponHolder svg {
    width: 18px;
    opacity: 0.8;
    transition: all 0.3s;
    cursor: pointer;
}

.couponHolder svg:hover {
    opacity: 1;
}

@media (max-width: 800px) {

    .applyButton {
        margin-top: -10px !important;
    }

}