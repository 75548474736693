.holder {
  border: 1px solid var(--primary-black-color);
  margin-bottom: 30px;
}

.holder a {
  font-size: 16px;
}

.holder button {
  display: inline-flex;
  height: auto !important;
  min-height: 40px;
  width: auto;
  max-width: 100%;
  padding: 5px 15px;
}

.header {
  min-height: 70px;
  border-bottom: 1px solid var(--primary-black-color);
  padding: 15px 25px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: rgb(255, 255, 255, 0.7);
}

.header svg {
  min-width: 30px;
  min-height: 30px;
  margin-right: 20px;
}

.holder.canceled .header svg {
  opacity: 0.3;
}

.header h2 {
  margin: 0;
  font-size: 22px;
}

.header p {
  margin: 0;
  margin-top: 5px;
}

.content {
  padding: 25px;
}

.flexHolder {
  display: flex;
}

.flexHolder p {
  margin-top: 0;
}

.flexHolder .left {
  width: 200px;
}

.flexHolder .left button {
  width: 100%;
}

.flexHolder .dateHolder {
  margin: auto;
  position: relative;
  width: 140px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flexHolder .clinicHolder h4 {
  margin-bottom: 5px;
  text-align: center;
}

.flexHolder .clinicHolder .address {
  width: 100%;
  font-size: 12.5px;
  line-height: 180%;
  text-align: center;
  display: block;
  margin-bottom: 20px;
}

.holder.canceled .flexHolder .dateHolder {
  opacity: 0.3;
}

.flexHolder .dateHolder svg {
  width: 100%;
  height: 100%;
  position: absolute;
  stroke-width: 0.5px;
}

.flexHolder .dateHolder .day {
  font-size: 30px;
  margin-top: 50px;
}

.flexHolder .dateHolder .what {
  font-size: 40px;
}

.flexHolder .right {
  flex: 1;
  margin-left: 30px;
}

.infoHolder {
  padding: 25px;
  text-align: center;
  border: 1px solid var(--primary-black-color);
  margin-bottom: 20px;
}

.infoHolder p {
  margin-top: 0;
  margin-bottom: 10px;
}

@media (max-width: 700px) {
  .flexHolder {
    flex-direction: column;
  }

  .flexHolder .left {
    width: 100%;
    margin-bottom: 30px;
  }

  .flexHolder .right {
    margin-left: 0px;
  }
}
