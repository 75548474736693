.holder {
    display: flex;
    height: 454px;
}

.calendarCol {
    flex: 0.8;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    overflow:hidden;
}

.calendarHolder {
    margin-top: 10px;
    flex: 1;
}

.timeCol {
    display: flex;
    flex: 0.2;
    flex-direction: column;
}

@media (max-width: 800px) {

    .holder {
        height: 420px;
    }

}